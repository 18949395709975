import { Injectable } from '@angular/core';

@Injectable()
export class userService {
  data: any;

  constructor() { }

  setData(data: any) {
    this.data = data;
  }

  getData() {
    return this.data;
  }
}
