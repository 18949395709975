import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { userService } from '../../services/user.service';
import { globals } from 'src/app/globals';
import { ToastService } from '../../services/toast.service';
import { ModalController } from '@ionic/angular';
import { DataServiceService } from 'src/app/services/data-service.service';
import { ApiServiceService } from 'src/app/services/api-service.service';
@Component({
  selector: 'app-jobs',
  templateUrl: 'jobs.page.html',
  styleUrls: ['jobs.page.scss']
})
export class JobsPage implements OnInit {
  // Variables
  user: any = {};
  timeGreeting: any = {};
  jobs: any = {};
  jobsCount: any = 0;
  jobsOffset: any = {};
  jobSearch: any = {};
  selectedJob: any = {};
  allJobCount: number;
  managerOrders: any = {};
  managerIncidents: any = {};
  ordersChart: any = {};
  incidentsChart: any = {};
  managerView = 0;
  options: any = {};
  toggleNumber = false;
  jobFilter = 0;
  orderCount: any;
  incidentCount: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public userService: userService,
    private toastService: ToastService,
    private http: HttpClient,
    public modalCtrl: ModalController,
    private dataService: DataServiceService,
    private apiService: ApiServiceService
  ) {
    // Fluid setting of variables
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd && this.router.url.indexOf('/dashboard/jobs') > -1) {
        if (this.user.permission == 4) {
          this.router.navigate(['dashboard/customer-orders']);
        };
        let now = new Date();
        let hour = now.getHours();
        if (hour < 12){
          if (this.user) {
            this.timeGreeting = 'Good morning ' + this.user.first_name;
          }
          else {
            this.timeGreeting = 'Good morning';
          }
        }
        else if (hour >= 12 && hour < 17){
          if (this.user) {
            this.timeGreeting = 'Good afternoon ' + this.user.first_name;
          }
          else {
            this.timeGreeting = 'Good afternoon';
          }
        }
        else {
          if (this.user) {
            this.timeGreeting = 'Good evening ' + this.user.first_name;
          }
          else {
            this.timeGreeting = 'Good evening';
          }
        }
        this.jobsOffset = 0;
        this.getJobs();
        this.toggleNumber = false;
        this.getNotifications();
      }
    });
  }

  // Toggle functionality - used to toggle between orders and incidents
  managerToggle() {
    this.toggleNumber = !this.toggleNumber;
    if (this.toggleNumber) {
      this.jobs = this.managerIncidents;
      this.options = this.incidentsChart;
    } else {
      this.jobs = this.managerOrders;
      this.options = this.ordersChart;
    }
  }

  // Function to create chart data
  createChartData(colors, count, labels, ids) {
    // Setting chart data as required by eCharts
    const cData: any = {
      tooltip: {
        trigger: 'item',
        textStyle: {
          fontSize: '20'
        },
      },
      series: [
        {
          //name: this.user.company_name,
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: false,
              fontSize: '40',
              fontWeight: 'bold',
            }
          },
        }
      ]
    };
    /* Pushing data into options object for eChart */
    const innerData = [];
    count.forEach((e,i) => {
      const x: any = {};
      x.value = e;
      x.name = labels[i];
      x.id = ids[i];
      innerData.push(x);
    });
    cData.series[0].data = innerData;
    /* Pushing colous into options object for eChart */
    cData.series[0].color = colors;
    /* Returning full options object for eChart */
    return cData;
  }

  // Used for filtering the orders/incidents lists via pie chart segments
  childData(x) {
    this.jobFilter = 1;
    if (!this.toggleNumber) { // 0 is orders
      this.jobs = this.managerOrders;
      if (x.id == 10) {
        this.jobs = this.jobs.filter(job => job.completed == 1);
      }
      else {
        this.jobs = this.jobs.filter(job => job.stage_grouping == x.id);
      }
    } else { // 1 is incidents
      this.jobs = this.managerIncidents;
      this.jobs = this.jobs.filter(job => job.incident_id == x.id);
    }
  };

  // Function to reset the filtering
  resetFilter() {
    if (!this.toggleNumber) { // 0 is orders
        this.jobs = this.managerOrders;
        this.toastService.showToast({ header: 'Success', message: 'Filter has been reset' });
      } else { // 1 is incidents
        this.jobs = this.managerIncidents;
        this.toastService.showToast({ header: 'Success', message: 'Filter has been reset' });
      }
  }

  // Function to get full jobs data - orders, incidents etc
  getJobs() {
    this.toggleNumber = false;
    // Getting user data and creating variables
    this.user = JSON.parse(window.localStorage.getItem('userService'));
    this.user.offset = this.jobsOffset;
    this.jobSearch = {};
    this.jobs = {};
    // Query to get job data
    this.apiService.getJobs(this.user).subscribe((data: any) => {
      if (data.status == 200 && data.body.response != 204 ) {
        // Get job data for manager
        if (this.user.permission == 2 || this.user.permission == 6) {
          this.orderCount = data.body.orders.length;
          this.incidentCount = data.body.incidentOrders.length;
          this.managerOrders = data.body.orders;
          if (this.managerOrders.length > 0) {
            // Calling service to add cdn to images
            this.dataService.cdnParser(this.managerOrders);
          }
          this.managerIncidents = data.body.incidentOrders;
          if (this.managerIncidents.length > 0) {
            this.dataService.cdnParser(this.managerIncidents);
          }
          this.jobs = this.managerOrders;
          // Calling chart builder function and setting variable
          this.ordersChart = this.createChartData(
            data.body.stageColours,
            data.body.stageCounts,
            data.body.stageLabels,
            data.body.stageIds
          );
          // Calling chart builder function and setting variable
          this.incidentsChart = this.createChartData(
            data.body.incidentColours,
            data.body.incidentCounts,
            data.body.incidentLabels,
            data.body.incidentIds
          );
          this.options = this.ordersChart;
          this.allJobCount = this.orderCount + this.incidentCount;
          this.jobsCount = this.orderCount + this.incidentCount;
        }
        // Get job data for operators and supervisors
        else {
          this.allJobCount = data.body.count;
          this.jobsCount = data.body.count;
          this.jobs = data.body.dataArray;
          if (this.jobs && this.jobs.length > 0) {
            this.dataService.cdnParser(this.jobs);
          }
        }
      }
      else {
        this.toastService.showToast({ header: 'Error', message: data.body.responseDescription });
      }
    }, err => {
    });
  };

  // Used to get more jobs items
  moreJobs() {
    this.jobsOffset = this.jobsOffset + 50;
    this.getJobs();
  }

  // Function to refresh jobs list
  refreshJobs(event) {
    this.jobsOffset = 0;
    this.getJobs()
    setTimeout(() => {
      event.target.complete();
    }, 500);
  }

  // Function to search for jobs
  searchJobs(event: any) {
    if (event && event.key === "Enter") {
      this.jobFilter = 0;
      // If it's not a number = normal search. If it is a number, it removes the 0's which makes it searchable
      const theInput = isNaN(this.jobSearch.input) ? this.jobSearch.input : parseInt(this.jobSearch.input.toString(), 10);
      this.apiService.searchJobs(theInput).subscribe((data: any) => {
        if (data.body.success == true) {
          if (data.body.response == 200) {
            this.jobs = data.body.dataArray;
            if (this.jobs.length > 0) {
              this.dataService.cdnParser(this.jobs);
            }
            this.jobsCount = data.body.count;
            this.jobSearch.searchedInput = this.jobSearch.input;
            this.jobSearch.input = '';
          } else if (data.body.response == 204) {
            this.jobs = '';
            this.jobsCount = data.body.count;
            this.jobSearch.searchedInput = this.jobSearch.input;
            this.jobSearch.input = '';
          }
        }
        else {
          this.toastService.showToast({ header: 'Error', message: data.body.responseDescription });
        }
      }, err => {
      });
    }
  }

  // Function to enter a job (non-manager)
  enterJobFunc(x) {
    this.apiService.enterJobFunc(x).subscribe((data: any) => {
      if (data.body.success == true && data.body.response == 200) {
        this.router.navigate(['dashboard/job-stage'], {
          state: {
            job: x,
            defects: data.body.dataArray.scrapages,
            fields: data.body.dataArray.fields,
            machines: data.body.dataArray.machines,
            timers: data.body.dataArray.timers,
            amendments: data.body.dataArray.amendments
          }
        });
      }
      else if (data.body.success == true && data.body.response == 206) {
        this.toastService.showToast({ header: 'Information', message: 'Checks outstanding' });
        this.router.navigate(['dashboard/job-checks'], {state: {job: x, checklists: data.body.dataArray}});
      }
      else {
        this.toastService.showToast({ header: 'Error', message: data.body.responseDescription });
      }
    }, err => {
    });
  };

  // Function to select and go to order details views
  selectJob(x: any) {
    if (x.value.on_hold == 1) {
      this.toastService.showToast({ header: 'Error', message: 'Job is on hold' });
    }
    else {
      // If manager permission
      if (this.user.permission == 2 || this.user.permission == 6) {
        this.apiService.getJobById(x).subscribe((data: any) => {
          if (data.body.success === true) {
            // Go to the manager stage (manager order overview/details)
            this.router.navigate(['dashboard/manager-stage'], {
              state: {
                job: x,
                jobData: data.body.order,
                feedbackReasons: data.body.nonConReasons
              }
            });
          }
          else {
            this.toastService.showToast({ header: 'Error', message: data.body.responseDescription });
          }
        }, err => {
        });
      }
      else if (x.value.timer == 1 || this.user.permission == 3) {
        if (x.value.timer != 1 && this.user.permission == 3) {
          this.apiService.setOperator(x).subscribe((data: any) => {
            if (data.body.success == true) {
              this.enterJobFunc(x);
            }
            else {
              this.toastService.showToast({ header: 'Error', message: data.body.responseDescription });
            }
          }, err => {
          });
        } else {
          this.enterJobFunc(x);
        }
      }
      else {
        this.apiService.getJobStages(x).subscribe((data: any) => {
          if (data.body.success == true) {
            this.router.navigate(['dashboard/job-assign'], {state: {job: x, stages: data.body.dataArray}});
          }
          else {
            this.toastService.showToast({ header: 'Error', message: data.body.responseDescription });
          }
        }, err => {
        });
      }
    }
  }

  // Navigate to clock in page taking the user variable with
  goClockInOut() {
    this.router.navigate(['/clock-in-out'], {state: {user: this.user}});
  }

  // checks for new notifications
  getNotifications() {
    this.apiService.getNotifications().subscribe((data: any) => {
      if (data.body.response == 200) {
        this.apiService.setNotificationCount(data.body.unreadCount);
      }
    }, err => {
    });
  }

  goCreateJob() {
    this.router.navigate(['/dashboard/create-order']);
  }

  // Simple function to return 0 which is used to order *ngFor loop
  returnZero() {
    return 0;
  }

  goKanban() {
    this.router.navigate(['/dashboard/kanban']);
  }

  goLog() {
    this.router.navigate(['/dashboard/log']);
  }

  // Calling the user data. If there's not any user data, it takes the user to the login page
  ngOnInit() {
    this.user = JSON.parse(window.localStorage.getItem('userService'));

    if(!this.user) {
      this.router.navigate(['/login']);
    } else {
      if (this.user.permission == 3 || this.user.permission == 5) {
        this.apiService.setScannerStatus(1);
      } else {
        this.apiService.setScannerStatus(0);
      }
    }
  }

}
