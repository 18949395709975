import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastController } from '@ionic/angular';

@Injectable()
export class ToastService {
  
  constructor(public toastCtrl: ToastController) { }

  showToast(data) {
    this.toastCtrl.create({
      header: data.header,
      message: data.message,
      cssClass: 'toast-truce',
      duration: 1800,
      position: 'top',
    }).then((toast) => {
      toast.present();
    });
  }

}
