import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { globals } from 'src/app/globals';
import { ToastService } from './toast.service';
import { BehaviorSubject, observable, Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  user: any;
  private notificationCount = new BehaviorSubject<any>(null);
  private scannerStatus = new BehaviorSubject<any>(null);
  private userPermission = new BehaviorSubject<any>(null);
  constructor(
    private http: HttpClient,
    private toastService: ToastService
  ) { }

  /**************************/
  /***** MAIN REQUESTS ******/
  /**************************/

  /***** FEEDBACK ******/
  getFeedback(selectedJob: any): Observable<any> {
    const user = this.getUser();
    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getFeedback');
    const body = {
      "order_id": selectedJob.value.id
    };
    return this.http.post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(error => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(error);
        })
      );
  };

  addFeedback(header, param, body): Observable<any> {
    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(error => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(error);
        })
      );
  }

  resolveFeedback(header, param, body): Observable<any> {
    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** GET JOB DATA ******/
  getJobs(user: any): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getJobs');
    const body = user;

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  getOperationalOrders(kanbanConfig: any): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', kanbanConfig.token)
      .set('apiFunctionName', 'searchOperationalOrders');
    const body = kanbanConfig;

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  getJobById(selectedJob: any): Observable<any> {
    const user = this.getUser();
    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getOrderById');
    const body = {
      "id": selectedJob.value.id
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  getOrderStages(selectedJob: any): Observable<any> {
    const user = this.getUser();
    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getOrderStages');
    const body = {
      "process_id": selectedJob.value.process_id,
      "cid": selectedJob.value.cid,
      "purchase_order": selectedJob.value.purchase_order,
      "job": selectedJob.value.job,
      "line_item": selectedJob.value.line_item
    };
    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  getOrderSubProcesses(theOrder: any, theStage: any): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getOrderSubProcess');

    const body = {
      order: theOrder.value,
      stage: theStage.value
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  searchJobs(theInput): Observable<any> {
    const user = this.getUser();
    let body = {};

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'searchJobs');

    // limit jobs for operators
    if (user.permission == 3) {
      var all = 0;
    }
    else {
      var all = 1;
    }

    body = {
      query: theInput,
      cid: user.cid,
      id: user.id,
      showAll: all
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  enterJobFunc(x) {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'enterJob');
    const body = {
      'operator': user.id,
      'permission': user.permission,
      'cid': user.cid,
      'job': x.value.id,
      'stage': x.value.stage
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  setOperator(x) {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'setOperator');

    const body = {
      'operator': user.id,
      'job': x.value.id,
      'stage': x.value.stage
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  getOperatorLog() {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getOperatorLog');
    const body = user;

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  searchOperatorLogs(input) {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'searchOperatorLog');
    const body = {
      id: user.id,
      permission: user.permission,
      cid: user.cid,
      query: input
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  getJobStages(x) {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getJobStages');
    const body = x.value;

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  getOrderData(job): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getOrderNotes');

    const body = {
      cid: user.cid,
      job: job.value.job,
      id: job.value.id
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  addNewMachineLog(newLog): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'addResourceMachine');

    const body = newLog;

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /******** GET CUSTOMER DATA **********/
  getCustomerSuppliers(x): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getOrdersBySupplier');

    let body;

    if (x !== 0) {
      body = {
        cid: user.cid,
        query: x
      };
    } else {
      body = {
        cid: user.cid
      };
    }

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  getCustomerOrders(): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getCustomerDash');

      const body = {
        "cid": user.cid
      };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  getCustomerOrdersFlexible(x): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getCustomerOrders');

    let body;
    if (x === 0) {
      body = {
        "cid": user.cid
      };
    } else {
      body = {
        "cid": user.cid,
        "query": x
      };
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  getOrdersBySupplier(x): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getCustomerOrdersBySupplier');
    const body = {
      "cid": user.cid,
      "sid": x.company_id
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  /****** GET JOB PARTIALS ******/
  getOrderPartials(job, process) {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getOrderPartials');

    const body = {
      "cid": job.value.cid,
      "job": parseInt(job.value.job.toString(), 10),
      "line": job.value.line_item,
      "order": job.value.purchase_order,
      "stage": process
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );

  };

  /***** JOB NOTES ******/
  getJobNotes(selectedJob: any): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getJobNotes');
    const body = {
      job: selectedJob.value.job,
      stage: selectedJob.value.stage,
      cid: user.cid,
      line: selectedJob.value.line_item
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  addJobNote(selectedJob, newJobNote): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'addJobNote');
    const body = {
      job: selectedJob.value.job,
      note: newJobNote.note,
      value: newJobNote.valueBase,
      valueName: newJobNote.attachmentName,
      cid: user.cid,
      uid: user.id,
      line: selectedJob.value.line_item
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

    /***** JOB LABELS ******/
    getJobPrinting(selectedJob: any): Observable<any> {
      const user = this.getUser();
  
      const header = new HttpHeaders()
        .set('Content-Type', 'text/plain');
      const param = new HttpParams()
        .set('apiKey', globals.key)
        .set('apiToken', user.token)
        .set('apiFunctionName', 'getJobPrinting');
      const body = selectedJob.value;
  
      return this.http
        .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
        .pipe(
          retry(1),
          catchError(err => {
            this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
            return throwError(err);
          })
        );
    };

  /***** CLOCK IN AND OUT ******/
  checkClockPin(pin: number): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'checkClockinPin');
    const body = {
      'cid': user.cid,
      'pin': pin
    };
    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  checkClocknumb(numb: number): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'verifyClockinNumber');
    const body = {
      'cid': user.cid,
      'number': numb,
      sid: user.sid
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  clockInOut(id, type): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'clockinOut');
    const body = {
      'id': id,
      'sid': user.sid,
      'out': type
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  /******** NOTIFICATIONS *********/
  getNotifications(): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getNotifications');
    const body = {
      'cid': user.cid,
      'uid': user.id,
      'pid': user.permission,
      'platform': 1,
      'limit': 10
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  setNotificationCount(count: number) {
    this.notificationCount.next(count);
  }

  getNotificationCount(): Observable<any> {
    return this.notificationCount.asObservable();
  }

  setScannerStatus(numb: number) {
    this.scannerStatus.next(numb);
  }

  getScannerStatus(): Observable<any> {
    return this.scannerStatus.asObservable();
  }

  getUserPermission = async ()  => {
    const user = await this.getUser();
    return user.permission;
  }

  notifRead(x): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'readNotification');
    const body = {
      'nid': x.value.id,
      'uid': user.id
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  dismissNotif(x): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'dismissNotification');
    const body = {
      'nid': x.value.id,
      'uid': user.id
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  /******** PARTIALS - TIMELOGS & STAGE HISTORY **********/
  getTimeLogs(selectedPartial, log): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getTimelogRecords');
    const body = {
      'job': selectedPartial.value.id,
      'stage': log.stage
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  getorderLogs(selectedPartial): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getOrderLog');
    const body = {
      "id": selectedPartial.value.id,
      "pid": user.permission,
      "drill": 0 // leaving as 0 for now
    }

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  getOrderStageHistory(selectedPartial): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getOrderStageHistory');
    const body = {
      "id": selectedPartial.value.id
    }

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  /****** GET ORDER FORM DATA ******/
  getOrderForm(user: any): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getOrderForm');
    const body = user;

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** SEARCH PRODUCTS/PARTS ******/
  searchProducts(request: any): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', request.token)
      .set('apiFunctionName', 'searchProducts');
    const body = request;

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** CREATE ORDER  ******/
  createOrder(order: any): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', order.token)
      .set('apiFunctionName', 'addOrder');
    const body = order;

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** DELETE ACCOUNT  ******/
  deleteAccount(user: any): Observable<any> {
    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'deleteUser');
    const body = user;

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** ACKNOWLEDGE ORDER AMENDMENT ******/
  acknowledgeOrderAmendment(amendment: any): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'acknowledgeOrderAmendment');
    const body = amendment;

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** GET STOCK ******/
  getStock(query: any): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getStock');
    const body = {
      cid: user.cid,
      search: query
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** GET STOCKTAKE ******/
  getStockTake(query: any): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getStockTake');
    const body = {
      cid: user.cid,
      query: query
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** SUBMIT STOCKTAKE ******/
  submitStockTake(stockTake: any): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'submitStockTake');
    const body = {
      uid: user.id,
      sid: user.sid,
      stockTake: stockTake
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** GET STOCK MOVEMENTS ******/
  getStockMovements(): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getStockMovements');
    const body = {
      cid: user.cid,
      sid: user.sid
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** UPDATE STOCK ******/
  updateProduct(item: any): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'updateProduct');
    const body = item;

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** GET STOCK LEVELS ******/
  getStockLevels(item: any): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'getParentChildStockLevel');
    const body = item;

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** BOOK IN LOOKUP ******/
  lookupBookInItem(query: any): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'lookupBookInItem');
    const body = {
      'cid': user.cid,
      'query': query
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** BOOK IN GET LOCATIONS ******/
  searchStockBookInLocations(query: any): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'searchStockBookInLocations');
    const body = {
      'cid': user.cid,
      'query': query
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** BOOK IN ******/
  bookInStock(item: any, uid: any, sid: any, quantity: any, location: any, reference: any): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'bookInStock');
    const body = {
      'cid': user.cid,
      'id': item,
      'uid': uid,
      'sid': sid,
      'quantity': quantity,
      'location': location,
      'reference': reference
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** BOOK OUT LOOKUP ******/
  lookupBookOutItem(query: any): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'lookupBookOutItem');
    const body = {
      'cid': user.cid,
      'query': query,
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** BOOK OUT LOCATIONS ******/
  searchStockBookOutLocations(item: any): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'searchStockBookOutLocations');
    const body = {
      'id': item,
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** BOOK OUT ******/
  bookOutStock(item: any, quantity: any, location: any, reference: any): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'bookOutStock');
    const body = {
      'uid': user.id,
      'cid': user.cid,
      'sid': user.sid,
      'id': item,
      'quantity': quantity,
      'location': location,
      'reference': reference
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /****** STOCK TRANSFER ******/
  stockTransfer(item: any, quantity: any, fromLocation: any, toLocation: any): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'stockTransfer');
    const body = {
      'uid': user.id,
      'cid': user.cid,
      'sid': user.sid,
      'id': item,
      'quantity': quantity,
      'fromLocation': fromLocation,
      'toLocation': toLocation,
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  };

  /***** FILE UPLOAD ******/
  uploadFiles(y, z): Observable<any> {
    const user = this.getUser();

    const header = new HttpHeaders()
      .set('Content-Type', 'text/plain');
    const param = new HttpParams()
      .set('apiKey', globals.key)
      .set('apiToken', user.token)
      .set('apiFunctionName', 'uploadFile');
    const body = {
      'file': y,
      'type': z
    };

    return this.http
      .post<any>(globals.api, body, { observe: 'response', params: param, headers: header })
      .pipe(
        retry(1),
        catchError(err => {
          this.toastService.showToast({ header: 'Error', message: 'There was an error in your request' });
          return throwError(err);
        })
      );
  }

  /**************************/
  /***** SUB REQUESTS ******/
  /*************************/

  /***** GET USER DATA ******/
  getUser() {
    return JSON.parse(window.localStorage.getItem('userService'));
  }
}
