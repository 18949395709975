import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
})
export class JobComponent implements OnInit {
  @Input() job;
  @Input() operational;
  selectedJob: any = {};
  jobColor: any;
  user: any = {};

  constructor() {

  }

  ngOnInit() {
    this.user = JSON.parse(window.localStorage.getItem('userService'));
    if (this.job.value.grouping_colour) {
      this.jobColor = this.job.value.grouping_colour;
    } else if (this.job.value.incident_colour) {
      if (this.job.value.incident_colour == 'red') {
        this.jobColor = '#f03426';
      } else {
        this.jobColor = this.job.value.incident_colour;
      }
    }
  }

}
