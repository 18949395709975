import { NgModule } from '@angular/core';
import { Camera } from '@ionic-native/camera/ngx';
import { Printer } from '@ionic-native/printer/ngx';
// import { File } from '@awesome-cordova-plugins/file/ngx';
// import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
// import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouteReuseStrategy } from '@angular/router';
import { globals } from 'src/app/globals';
import { userService } from './services/user.service';
import { ToastService } from './services/toast.service';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { JobComponent } from 'src/app/components/job/job.component';
import { LogComponent } from 'src/app/components/log/log.component';
import { JobsPage } from 'src/app/dashboard/jobs/jobs.page';
import { NgxEchartsModule } from 'ngx-echarts';
// import { SortByOrdersPipe } from './sort-by-orders.pipe';

declare let AppUpdate: any;

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    CommonModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    NgxEchartsModule.forRoot({
    echarts: () => import('echarts'),
  })],
  // exports: [SortByOrdersPipe],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, userService, ToastService, JobComponent, JobsPage, LogComponent, Camera, Printer],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    library: FaIconLibrary,
  ) {
		library.addIconPacks(fas, fab, far);
	}
}
