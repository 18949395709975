import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss'],
})
export class LogComponent implements OnInit {
  @Input() log;

  user: any = {};

  constructor() { }

  ngOnInit() {
    this.user = JSON.parse(window.localStorage.getItem('userService'));
  }

}
