import { Injectable } from '@angular/core';
import { globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {

  constructor() { }

  // External service to modify image cdns
  cdnParser(data: any) {
    for (const e of data) {
      if (e.stage_image) {
        e.stage_image = globals.cdn + e.stage_image;
      }
      if (e.product_image) {
        e.product_image = globals.cdn + e.product_image;
      }
      if (e.image) {
        e.image = globals.cdn + e.image;
      }
      if (e.data) {
        e.data = globals.cdn + e.data;
      }
      if (e.file) {
        e.file = globals.cdn + e.file;
      }
      if (e.logo) {
        e.logo = globals.cdn + e.logo;
      }
      if (e.photo) {
        e.photo = globals.cdn + e.photo;
      }
    }
  }
}
